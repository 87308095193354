
import Vue from "vue";

export default Vue.extend({
  name: "PaymentMethodModal",
  props: {
    paymentMethod: {
      type: Object
    },
    isProfile: {
      type: Boolean
    },
    mode: {
      // add or edit
      type: String,
      default: "add"
    }
  },
  data() {
    return {
      data: {} as object,
      errors: {} as object,
      paymentAddBtnLoading: false,
      cardTypeOptions: [
        { label: "Debit Card", value: "visa", icon: "visa" },
        { label: "Debit Card", value: "paypal", icon: "paypal" },
        { label: "Debit Card", value: "mastercard", icon: "mastercard" }
      ],
      monthOptions: ["01", "02", "03", "04"],
      yearOptions: [2021, 2022, 2023],
      OpenIndicator: {
        render: (createElement: any) =>
          createElement("inline-svg", {
            attrs: {
              src: require(`@/assets/icons/arrow.svg`)
            }
          })
      }
    };
  },
  created() {
    this.resetPaymentMethod();
  },
  methods: {
    setPaymentMethod(data: object) {
      this.data = data;
    },
    resetPaymentMethod() {
      this.data = {
        isProfile: this.isProfile,
        card_type: "",
        card_number: "",
        card_holder_name: "",
        ccv: "",
        expiry_month: "",
        expiry_year: ""
      };
    },
    onSubmit() {
      this.paymentAddBtnLoading = true;

      this.mode === "add" ? this.create() : this.update();
    },
    create() {
      this.$store
        .dispatch("payment/ADD_PAYMENT_METHOD", this.data)
        .then(() => {
          this.paymentAddBtnLoading = false;
          this.$store.dispatch("profile/GET_PROFILE");
          //Hide
          (this.$refs["add-payment-method-modal"] as Vue & {
            hide: () => boolean;
          }).hide();
        })
        .catch(error => {
          this.paymentAddBtnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    update() {
      this.$store
        .dispatch("payment/UPDATE_PAYMENT_METHOD", this.data)
        .then(() => {
          this.paymentAddBtnLoading = false;
          this.$store.dispatch("profile/GET_PROFILE");
          //Hide
          (this.$refs["add-payment-method-modal"] as Vue & {
            hide: () => boolean;
          }).hide();
        })
        .catch(error => {
          this.paymentAddBtnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    hasError(type: string) {
      //@ts-ignore
      if (type in this.errors && this.errors[type][0].length > 0) {
        //@ts-ignore
        return { error: this.errors[type][0] };
      }
      return null;
    }
  }
});
